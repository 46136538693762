import keyBy from 'lodash/keyBy'
import mergeWith from 'lodash/mergeWith'
import values from 'lodash/values'

import type r4 from 'fhir/r4'

const preferredArrayMergeKeys = ['id', 'code'] as const

const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value !== null
}

const mergeWithCustomizer = (
  targetValue: unknown,
  changedValue: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't really care what the type is here
): any => {
  if (Array.isArray(targetValue) && Array.isArray(changedValue)) {
    const firstTargetValue = isObject(targetValue[0]) ? targetValue[0] : {}
    const firstChangedValue = isObject(changedValue[0]) ? changedValue[0] : {}

    const availableKeys = Object.keys(
      firstTargetValue || firstChangedValue || {},
    )

    const key = preferredArrayMergeKeys.find((key) => {
      return availableKeys.includes(key)
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- it's chill, all we care at this point is if it's an array or not, we already know the both objects being merged are of the same type so we're just merging arrays, so no harm no foul.
    return values(
      mergeWith(
        keyBy(targetValue, key),
        keyBy(changedValue, key),
        mergeWithCustomizer,
      ),
    )
  }

  if (typeof targetValue === 'object' && typeof changedValue === 'object') {
    return mergeWith(targetValue, changedValue, mergeWithCustomizer)
  }
}

export const modifyStructureDefinition = (
  original: r4.StructureDefinition,
  modifications: Partial<r4.StructureDefinition>,
): r4.StructureDefinition => {
  const target = structuredClone(original)
  const changes = {
    ...modifications,
    resourceType: modifications.resourceType || original.resourceType,
    abstract: modifications.abstract || original.abstract,
    kind: modifications.kind || original.kind,
    name: modifications.name || original.name,
    status: modifications.status || original.status,
    type: modifications.type || original.type,
    url: modifications.url || original.url,
  }

  mergeWith<r4.StructureDefinition, r4.StructureDefinition>(
    target,
    changes,
    mergeWithCustomizer,
  )

  return target
}
