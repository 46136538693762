import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/Period'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Period.start',
        path: 'Period.start',
        short: 'Starting date',
      },
      {
        id: 'Period.end',
        path: 'Period.end',
        short: 'Ending date',
      },
    ],
  },
})
