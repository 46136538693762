import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/Questionnaire'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Questionnaire',
        path: 'Questionnaire',
        max: '1',
      },
      {
        id: 'Questionnaire.id',
        path: 'Questionnaire.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Questionnaire.identifier',
        path: 'Questionnaire.identifier',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Questionnaire.status',
        path: 'Questionnaire.status',
        short: 'Status',
      },
      {
        id: 'Questionnaire.item.id',
        path: 'Questionnaire.item.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Questionnaire.item.required',
        path: 'Questionnaire.item.required',
        short: 'Required',
      },
      {
        id: 'Questionnaire.item.text',
        path: 'Questionnaire.item.text',
        short: 'Display text',
      },
      {
        id: 'Questionnaire.item.type',
        path: 'Questionnaire.item.type',
        short: 'Item type',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.linkId',
        path: 'Questionnaire.item.linkId',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.answerOption',
        path: 'Questionnaire.item.answerOption',
        short: 'Answer option',
      },
      {
        id: 'Questionnaire.item.answerOption.id',
        path: 'Questionnaire.item.answerOption.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Questionnaire.item.answerOption.value[x]',
        path: 'Questionnaire.item.answerOption.value[x]',
        type: [
          {
            code: 'string',
            _code: {
              extension: [
                {
                  url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-order',
                  valueInteger: 1,
                },
              ],
            },
          },
          {
            code: 'Coding',
            _code: {
              extension: [
                {
                  url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-order',
                  valueInteger: 0,
                },
              ],
            },
          },
        ],
      },
      {
        id: 'Questionnaire.item.answerOption.initialSelected',
        path: 'Questionnaire.item.answerOption.initialSelected',
        short: 'Selected by default',
      },
      {
        id: 'Questionnaire.item.initial.id',
        path: 'Questionnaire.item.initial.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Questionnaire.item.enableBehavior',
        path: 'Questionnaire.item.enableBehavior',
        short: 'Enable behavior',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.enableWhen',
        path: 'Questionnaire.item.enableWhen',
        short: 'Enable field when',
      },
      {
        id: 'Questionnaire.item.enableWhen.id',
        path: 'Questionnaire.item.enableWhen.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Questionnaire.item.enableWhen.question',
        path: 'Questionnaire.item.enableWhen.question',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.enableWhen.operator',
        path: 'Questionnaire.item.enableWhen.operator',
        short: 'Operator',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.enableWhen.answer[x]',
        path: 'Questionnaire.item.enableWhen.answer[x]',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Questionnaire.item.maxLength',
        path: 'Questionnaire.item.maxLength',
        short: 'Maximum number of characters',
      },
      {
        id: 'Questionnaire.item.prefix',
        path: 'Questionnaire.item.prefix',
        short: 'Short label to display before the item text',
      },
      {
        id: 'Questionnaire.item.item',
        path: 'Questionnaire.item.item',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
    ],
  },
})
