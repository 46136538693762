import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/questionnaire-optionExclusive'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  title: 'Option exclusive',
  snapshot: {
    element: [
      {
        id: 'Extension.value[x]',
        path: 'Extension.value[x]',
        short:
          'If this answerOption is selected, no other possible answers may be selected, even if the item is a repeating question.',
      },
    ],
  },
})
