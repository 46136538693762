/* eslint-disable no-console */

import set from 'lodash/set'
import ShortUniqueId from 'short-unique-id'
import { v4 as uuid } from 'uuid'

import itemTypeCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/item-type'
import publicationStatusCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/publication-status'
import quantityComparatorCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/quantity-comparator'
import questionnaireEnableBehaviorCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/questionnaire-enable-behavior'
import questionnaireEnableOperatorCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/questionnaire-enable-operator'
import questionnaireItemControlCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/questionnaire-item-control'
import resourceTypesCodeSystem from '@visiontree/fhir-resources/r4/hl7.org/fhir/resource-types'
import itemTypeValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/item-type'
import languagesValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/languages'
import publicationStatusValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/publication-status'
import quantityComparatorValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/quantity-comparator'
import questionnaireEnableBehaviorValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/questionnaire-enable-behavior'
import questionnaireEnableOperatorValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/questionnaire-enable-operator'
import questionnaireItemControlValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/questionnaire-item-control'
import resourceTypesValueSet from '@visiontree/fhir-resources/r4/hl7.org/fhir/ValueSet/resource-types'

import type r4 from 'fhir/r4'

import type { VtInput } from '@/components/vue-ui'
import type {
  EnhancedElementDefinition,
  EnhancedElementDefinitionBinding,
} from '@/types'

const { randomUUID } = new ShortUniqueId({ length: 6 })

interface StructureDefinitionToFormFieldsOptions {
  path?: string
  exclude?: Array<string>
}

const valueSets = {
  'http://hl7.org/fhir/ValueSet/languages': languagesValueSet,
  'http://hl7.org/fhir/ValueSet/resource-types|4.0.1': resourceTypesValueSet,
  'http://hl7.org/fhir/ValueSet/publication-status|4.0.1':
    publicationStatusValueSet,
  'http://hl7.org/fhir/ValueSet/item-type|4.0.1': itemTypeValueSet,

  'http://hl7.org/fhir/ValueSet/questionnaire-enable-behavior|4.0.1':
    questionnaireEnableBehaviorValueSet,
  'http://hl7.org/fhir/ValueSet/questionnaire-enable-operator|4.0.1':
    questionnaireEnableOperatorValueSet,
  'http://hl7.org/fhir/ValueSet/quantity-comparator|4.0.1':
    quantityComparatorValueSet,
  'http://hl7.org/fhir/ValueSet/questionnaire-item-control':
    questionnaireItemControlValueSet,
} as const

const codeSystems = {
  'http://hl7.org/fhir/publication-status': publicationStatusCodeSystem,
  'http://hl7.org/fhir/resource-types': resourceTypesCodeSystem,
  'http://hl7.org/fhir/questionnaire-enable-behavior':
    questionnaireEnableBehaviorCodeSystem,
  'http://hl7.org/fhir/questionnaire-enable-operator':
    questionnaireEnableOperatorCodeSystem,
  'http://hl7.org/fhir/item-type': itemTypeCodeSystem,
  'http://hl7.org/fhir/quantity-comparator': quantityComparatorCodeSystem,
  'http://hl7.org/fhir/questionnaire-item-control':
    questionnaireItemControlCodeSystem,
} as const

const inputTypes = {
  canonical: 'url',
  boolean: 'checkbox',
  code: 'select',
  date: 'date',
  dateTime: 'datetime-local',
  integer: 'number',
  markdown: 'textarea',
  string: 'text',
  uri: 'url',
  BackboneElement: 'fieldset',
  Period: 'period',
  'http://hl7.org/fhirpath/System.String': 'text',
} as const

const getValueSet = (url?: string): r4.ValueSet | undefined => {
  const valueSet = valueSets[url as keyof typeof valueSets]
  const output = { ...valueSet }

  if (valueSet && output.compose) {
    output.compose.include = output.compose.include.map((composeInclude) => {
      if (!composeInclude.system) {
        return composeInclude
      }

      const codeSystem =
        codeSystems[composeInclude.system as keyof typeof codeSystems]

      if (!codeSystem) {
        console.warn('missing codeSystem', composeInclude.system)
      }

      if (!codeSystem?.concept) {
        return composeInclude
      }

      return {
        ...composeInclude,
        concept: codeSystem.concept,
      }
    })
  }

  if (!valueSet) {
    console.warn('missing valueSet', url)
  }

  return output
}

const getTagName = (
  elementDefinition: EnhancedElementDefinition,
): 'input' | 'select' | 'textarea' | 'checkbox' | typeof VtInput => {
  if ((elementDefinition.type?.length ?? 0) > 1) {
    console.warn('Multiple types unsupported.', elementDefinition.type)
  }

  const type = elementDefinition?.type?.[0]

  if (!type) {
    return 'input'
  }

  const inputType =
    type.code in inputTypes
      ? inputTypes[type.code as keyof typeof inputTypes]
      : undefined

  if (
    inputType === 'select' &&
    elementDefinition.binding?.strength !== 'required'
  ) {
    return 'input'
  }

  if (
    inputType === 'select' ||
    inputType === 'textarea' ||
    inputType === 'checkbox'
  ) {
    return inputType
  }

  return 'input'
}

const getInputAttrs = (elementDefinition: EnhancedElementDefinition) => {
  const tagName = getTagName(elementDefinition)
  const type =
    tagName === 'input'
      ? inputTypes[
          elementDefinition?.type?.[0].code as keyof typeof inputTypes
        ] || 'text'
      : undefined

  const output: Record<string, string | number | boolean> = {
    id: `input-${elementDefinition.id || uuid()}`,
    multiple:
      elementDefinition.max === '*' || Number(elementDefinition.max) > 1,
    required: elementDefinition.min !== undefined && elementDefinition.min > 0,
  }

  if (type) {
    output.type = type
  }

  if (type === 'number' && typeof elementDefinition.min === 'number') {
    output.min = elementDefinition.min
  }
  if (
    type === 'number' &&
    elementDefinition.max &&
    !Number.isNaN(Number(typeof elementDefinition.max))
  ) {
    output.max = elementDefinition.max
  }

  return output
}

type Option = {
  label: string
  value: string
}

type GroupOption = {
  type: 'group'
  key: string
  label: string
  children: Array<Option>
}

export const getValueOptions = (
  elementDefinition: EnhancedElementDefinition,
): Array<GroupOption | Option> => {
  return (
    elementDefinition.binding?.valueSet?.compose?.include.flatMap<
      GroupOption | Option
    >((composeInclude) => {
      if (!composeInclude.concept) {
        return []
      }

      return composeInclude.concept.map((concept) => {
        if ('concept' in concept && Array.isArray(concept.concept)) {
          return {
            type: 'group',
            label: concept.display || concept.code,
            key: concept.code,
            children: concept.concept.map((childConcept) => {
              return {
                label: childConcept.display || childConcept.code,
                value: childConcept.code,
              }
            }),
          }
        }

        return { label: concept.display || concept.code, value: concept.code }
      })
    }) || []
  )
}

const supportedElementDefinition = (
  definition: r4.ElementDefinition,
  options?: StructureDefinitionToFormFieldsOptions,
): boolean => {
  if (definition.path === options?.path && options.path.split('.').length === 1)
    return true

  if (options?.path && !definition.path.startsWith(`${options.path}.`)) {
    return false
  }

  if (options?.exclude && Array.isArray(options.exclude)) {
    if (options.exclude.includes(definition.path)) {
      return false
    }
  }

  const isScoped = !!options?.path
  const scopedPath = isScoped
    ? definition.path.replace(`${String(options.path)}.`, '')
    : definition.path

  if (isScoped ? !scopedPath : scopedPath.split('.').length === 1) {
    // root object is ignored for now
    return false
  }

  if (!definition.type) {
    return false
  }

  if (!(definition.type?.[0]?.code in inputTypes)) {
    return false
  }

  if (
    isScoped
      ? scopedPath.split('.').length > 1
      : scopedPath.split('.').length > 2
  ) {
    return false
  }

  return true
}

export const enhanceElementDefinition = (
  definition: r4.ElementDefinition | EnhancedElementDefinition,
): EnhancedElementDefinition => {
  const binding: EnhancedElementDefinitionBinding | undefined =
    definition.binding
      ? {
          ...definition.binding,
          valueSet:
            typeof definition.binding.valueSet !== 'string'
              ? definition.binding.valueSet
              : undefined,
        }
      : undefined

  if (binding && typeof definition?.binding?.valueSet === 'string') {
    binding.valueSet = getValueSet(definition.binding?.valueSet)
  }

  return {
    ...definition,
    binding,
  }
}

const getLabelText = (
  element: EnhancedElementDefinition,
  elements: Array<r4.ElementDefinition> | Array<EnhancedElementDefinition>,
) => {
  if (element.id?.startsWith('Extension.extension:')) {
    const extensionKey = element.id.match(/^Extension\.extension:(.+)\./)?.[1]
    const parentExtensionId = `Extension.extension:${String(extensionKey)}`

    const extensionParentElement = elements.find((potentialParent) => {
      return potentialParent.id === parentExtensionId
    })

    if (extensionParentElement) {
      return (
        extensionParentElement.short ||
        extensionParentElement.id ||
        extensionParentElement.path
      )
    }
  }
  return element.short || element.id || element.path
}

export const getStructureDefinitionElements = (
  definition: r4.StructureDefinition,
  options?: StructureDefinitionToFormFieldsOptions,
): Array<EnhancedElementDefinition> => {
  if (!definition.snapshot?.element) {
    return []
  }

  return definition.snapshot.element
    .filter((snapshotElement) => {
      return supportedElementDefinition(snapshotElement, options)
    })
    .filter((element) => {
      const hiddenElement = element.extension?.find((extension) => {
        return (
          extension.url ===
            'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden' &&
          extension.valueBoolean
        )
      })

      return !hiddenElement
    })
    .map((elementDefinition) => {
      return enhanceElementDefinition(elementDefinition)
    })
}

export const elementDefinitionToFormField = (
  elementDefinition: r4.ElementDefinition | EnhancedElementDefinition,
  snapshotElements:
    | Array<r4.ElementDefinition>
    | Array<EnhancedElementDefinition>,
) => {
  const enhancedDefinition = enhanceElementDefinition(elementDefinition)
  const inputAttrs = getInputAttrs(enhancedDefinition)

  return {
    elementDefinition: enhancedDefinition,
    type: getTagName(enhancedDefinition),
    id: String(inputAttrs.id),
    inputTagName: getTagName(enhancedDefinition),
    options: getValueOptions(enhancedDefinition),
    labelText: getLabelText(enhancedDefinition, snapshotElements),
    inputAttrs,
  }
}

export const structureDefinitionToFormFields = (
  definition: r4.StructureDefinition,
  options?: StructureDefinitionToFormFieldsOptions,
) => {
  if (!definition.snapshot?.element) {
    return []
  }

  return definition.snapshot.element
    .filter((snapshotElement) => {
      return supportedElementDefinition(snapshotElement, options)
    })
    .filter((element) => {
      const hiddenElement = element.extension?.find((extension) => {
        return (
          extension.url ===
            'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden' &&
          extension.valueBoolean
        )
      })

      return !hiddenElement
    })
    .map((elementDefinition) => {
      return elementDefinitionToFormField(
        elementDefinition,
        definition.snapshot?.element || [], // typescript doesn't believe that we've already verified `definition.snapshot.element` is present above
      )
    })
}

const resolveElementPath = (
  elementDefinition: EnhancedElementDefinition,
  snapshotElements: EnhancedElementDefinition[],
) => {
  let path = elementDefinition.path

  if (elementDefinition.id && elementDefinition.id.includes(':')) {
    path = elementDefinition.id
  }

  if (path.includes(':')) {
    const arrayKeys: Array<string> = []

    snapshotElements.forEach((element) => {
      if (!element.id) {
        return
      }

      const arrayKey = element.id.match(/^.+:(.+)\./)?.[1]

      if (arrayKey && !arrayKeys.includes(arrayKey)) {
        arrayKeys.push(arrayKey)
      }
    })

    const arrayKey = path.match(/^.+:(.+)\./)?.[1]
    const arrayIndex = arrayKeys.indexOf(arrayKey || '')

    path = path.replace(`:${String(arrayKey)}`, `[${arrayIndex}]`)
  }

  if (path.includes('value[x]')) {
    const typeCode = elementDefinition.type?.at(0)?.code

    if (typeCode) {
      path = path.replace(
        '[x]',
        typeCode.charAt(0).toUpperCase() + typeCode.slice(1),
      )
    }
  }

  path = path.split('.').slice(1).join('.')

  if (path.includes(':')) {
    return ''
  }

  return path
}

const getFixedValue = (elementDefinition: EnhancedElementDefinition) => {
  const fixedValueKeys = Object.keys(elementDefinition).filter((key) => {
    return key.startsWith('fixed')
  })

  return elementDefinition[fixedValueKeys[0] as keyof typeof elementDefinition]
}

export const buildInstanceFromExtensionDefinition = (
  definition: r4.StructureDefinition,
): r4.Extension => {
  if (definition.type !== 'Extension') {
    throw new Error('StructureDefinition.kind must be "Extension"')
  }

  if (!definition.snapshot) {
    throw new Error('StructureDefinition.snapshot.element required')
  }

  const output: r4.Extension = {
    url: '',
  }

  definition.snapshot.element
    .map(enhanceElementDefinition)
    .map((element, _index, allElements) => {
      return {
        path: resolveElementPath(element, allElements),
        element,
      }
    })
    .filter((item) => {
      return (
        item.path &&
        ((item.element.max !== '*' && item.element.max !== '0') ||
          item.path.endsWith('.id') ||
          item.path === 'id')
      )
    })
    .forEach((item) => {
      if (item.path.endsWith('.id') || item.path === 'id') {
        set(output, item.path, randomUUID())

        return
      }

      let initialValue = getFixedValue(item.element)

      if (item.element.type?.[0].code === 'boolean') {
        initialValue = false
      }

      set(output, item.path, initialValue)
    })

  return output
}

export const getSortedElementDefinitionTypes = (
  elementDefinition: EnhancedElementDefinition,
) => {
  return (
    elementDefinition.type?.sort((firstType, secondType) => {
      const firstOrder =
        firstType._code?.extension?.find(
          (extension) =>
            extension.url ===
            'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-order',
        )?.valueInteger ?? Number.POSITIVE_INFINITY
      const secondOrder =
        secondType._code?.extension?.find(
          (extension) =>
            extension.url ===
            'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-order',
        )?.valueInteger ?? Number.POSITIVE_INFINITY

      return firstOrder - secondOrder
    }) || []
  )
}
