import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/questionnaire-hidden'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  title: 'Hide item',
  snapshot: {
    element: [
      {
        id: 'Extension.value[x]',
        path: 'Extension.value[x]',
        short: 'Hide item from user',
      },
    ],
  },
})
