import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/questionnaire-minOccurs'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  title: 'Min occurs',
  snapshot: {
    element: [
      {
        id: 'Extension.value[x]',
        path: 'Extension.value[x]',
        short:
          'Minimum number of times the group must appear, or the minimum number of answers for a question',
      },
    ],
  },
})
