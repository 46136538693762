import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/Quantity'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Quantity',
        path: 'Quantity',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Quantity.id',
        path: 'Quantity.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Quantity.extension',
        path: 'Quantity.extension',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Quantity.comparator',
        path: 'Quantity.comparator',
        short: 'Quantity comparator',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Quantity.code',
        path: 'Quantity.code',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
    ],
  },
})
