import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/questionnaire-itemControl'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Extension.value[x]',
        path: 'Extension.value[x]',
        short: 'Item display style',
      },
    ],
  },
})
