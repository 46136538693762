import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/CodeableConcept'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'CodeableConcept.id',
        path: 'CodeableConcept.id',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'CodeableConcept.extension',
        path: 'CodeableConcept.extension',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
    ],
  },
})
