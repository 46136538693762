import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/Coding'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Coding.userSelected',
        path: 'Coding.userSelected',
        extension: [
          {
            url: 'https://studio.visiontree.com/fhir/StructureDefinition/elementdefinition-hidden',
            valueBoolean: true,
          },
        ],
      },
      {
        id: 'Coding.version',
        path: 'Coding.version',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Coding.code',
        path: 'Coding.code',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
    ],
  },
})
