import base from '@visiontree/fhir-resources/r4/hl7.org/fhir/StructureDefinition/translation'

import { modifyStructureDefinition } from '@/utils/modifyStructureDefinition'

export const definition = modifyStructureDefinition(base, {
  snapshot: {
    element: [
      {
        id: 'Extension.extension:lang',
        path: 'Extension.extension',
        short: 'Language code',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Extension.extension:content',
        path: 'Extension.extension',
        short: 'Content',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Extension.extension:lang.value[x]',
        path: 'Extension.extension.value[x]',
        short: 'Language',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
      {
        id: 'Extension.extension:content.value[x]',
        path: 'Extension.extension.value[x]',
        short: 'Content',
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/elementdefinition-translatable',
            valueBoolean: false,
          },
        ],
      },
    ],
  },
})
